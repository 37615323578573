import { Button, TextField, FormControl, Select, MenuItem } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FaSave } from 'react-icons/fa';
import { getAllParameters, updateParameters } from '../../../server/server';
import HelpButton from '../../../common/HelpButton';
import HelpPageModal from '../../modals/HelpPageModal';
import { generateYears } from '../../utils/util';

interface Parameter {
	id: string;
	name: string;
	value: string;
	description: string;
	isVisible: boolean;
}

function Parametrization() {
	const [parameters, setParameters] = useState<Parameter[]>([]);
	const [fromQ, setFromQ] = useState<string>('');
	const [toQ, setToQ] = useState<string>('');
	const [fromYear, setFromYear] = useState<string>('');
	const [toYear, setToYear] = useState<string>('');
	const [helpModal, setHelpModal] = useState(false);

	const QUARTER_MAP = ['I', 'II', 'III', 'IV'];
	const YEAR_MAP = generateYears(2020, 2030);

	const closeHelpModal = () => {
		setHelpModal(false);
	};
	useEffect(() => {
		getAllParameters().then((response: any) => {
			setParameters(response.data);

			// Parse and set NPAA dates
			const npaaFrom = response.data.find((p: Parameter) => p.name === 'npaa-from')?.value;
			const npaaTo = response.data.find((p: Parameter) => p.name === 'npaa-to')?.value;

			if (npaaFrom) {
				const [year, quarter] = npaaFrom.split('/');
				setFromYear(year);
				setFromQ(quarter);
			}

			if (npaaTo) {
				const [year, quarter] = npaaTo.split('/');
				setToYear(year);
				setToQ(quarter);
			}
		});
	}, []);


	const handleInputChange = (index: number, event: any) => {
		const values = [...parameters];
		values[index].value = event.target.value;
		setParameters(values);
	};

	const handleSave = () => {
		// Save regular parameters
		parameters.forEach((parameter) => {
			if (parameter.name !== 'npaa-from' && parameter.name !== 'npaa-to') {
				updateParameters(parameter.id, { value: parameter.value });
			}
		});

		// Save NPAA dates
		const npaaFromParam = parameters.find(p => p.name === 'npaa-from');
		const npaaToParam = parameters.find(p => p.name === 'npaa-to');

		if (npaaFromParam) {
			updateParameters(npaaFromParam.id, {
				value: `${fromYear}/${fromQ}`
			});
		}

		if (npaaToParam) {
			updateParameters(npaaToParam.id, {
				value: `${toYear}/${toQ}`
			});
		}
	};
	return (
		<div className='py-10 px-2 overflow-auto gray-small-scroll h-full'>
			<div className='flex gap-6'>
				<h1 className='text-[#04438b] text-4xl font-bold'>Параметризација</h1>
				<HelpButton onClick={() => setHelpModal(true)} />
			</div>
			<div className='rounded-lg border border-[#81848f3d] w-fit p-8 pb-4 mt-8 gap-2'>
				<div className='flex flex-col my-2'>
					{parameters.filter(p => p.isVisible).map((parameter, index) => (
						<div className='flex flex-col'>
							<p className='text-[#404040] text-base mb-5'>{parameter.description}</p>
							<TextField key={parameter.id} label={parameter.description} value={parameter.value} onChange={(event) => handleInputChange(index, event)} />
						</div>
					))}
				</div>
				<p className='text-[#404040] text-base mt-8'>НПАА период</p>

				<div className='flex'>
					<div className="flex items-end">
						<FormControl size="small" sx={{ m: 1, width: 85 }}>
							<p className="w-[160px] text-sm text-[#404040]">Од Квартала</p>
							<Select
								value={fromQ || ''}
								onChange={(e) => setFromQ(e.target.value)}
							>
								{QUARTER_MAP?.map((q) => (
									<MenuItem key={q} value={q}>{q}</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl size="small" sx={{ m: 1, width: 85 }}>
							<p className="w-[160px] text-sm text-[#404040]">Од Године</p>
							<Select
								value={fromYear || ''}
								onChange={(e) => setFromYear(e.target.value)}
							>
								{YEAR_MAP?.map((y) => (
									<MenuItem key={y} value={y}>{y}</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
					<div className="flex items-end">
						<FormControl size="small" sx={{ m: 1, width: 85 }}>
							<p className="w-[160px] text-sm text-[#404040]">До Квартала</p>
							<Select
								value={toQ || ''}
								onChange={(e) => setToQ(e.target.value)}
							>
								{QUARTER_MAP?.map((q) => (
									<MenuItem key={q} value={q}>{q}</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl size="small" sx={{ m: 1, width: 85 }}>
							<p className="w-[160px] text-sm text-[#404040]">До Године</p>
							<Select
								value={toYear || ''}
								onChange={(e) => setToYear(e.target.value)}
							>
								{YEAR_MAP?.map((y) => (
									<MenuItem key={y} value={y}>{y}</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				</div>
				<div className='w-full flex justify-end mt-12 items-center'>
					<Button variant='contained' sx={{ backgroundColor: '#0F2765' }} endIcon={<FaSave />} onClick={handleSave}>
						Потврди
					</Button>
				</div>
			</div>
			<HelpPageModal modalIsOpen={helpModal} closeModal={closeHelpModal} name={'parametrization'} />
		</div>
	);
}

export default Parametrization;
