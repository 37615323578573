import React from 'react';
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Font,
    pdf
} from '@react-pdf/renderer';

// Update font registration to use Fira Sans Condensed
Font.register({
    family: 'FiraSansCondensed',
    format: 'truetype',
    src: '/fonts/FiraSansCondensed-Regular.ttf'
});

const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontFamily: 'FiraSansCondensed'
    },
    header: {
        fontSize: 14,
        marginBottom: 20,
        borderBottom: 1,
        paddingBottom: 5
    },
    section: {
        marginBottom: 10
    },
    row: {
        flexDirection: 'row',
        borderBottom: '1px solid #ccc',
        paddingVertical: 5,
        marginBottom: 5
    },
    label: {
        width: '30%',
        fontSize: 10,
        color: '#666'
    },
    value: {
        width: '70%',
        fontSize: 10
    },
    table: {
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#ccc'
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc'
    },
    tableCell: {
        padding: 5,
        fontSize: 10,
        borderRightWidth: 1,
        borderRightColor: '#ccc'
    },
    subHeader: {
        fontSize: 12,
        marginTop: 15,
        marginBottom: 10,
        backgroundColor: '#f0f0f0',
        padding: 5
    },
    checkbox: {
        width: 12,
        height: 12,
        border: '1px solid black',
        marginRight: 5
    },
    checkboxRow: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 5
    }
});

const COMPACTIBILITY_MAP: { 0: string; 1: string; 2: string } = {
    0: 'није усклађен',
    1: 'делимично усклађен',
    2: 'поптпуно усклађен',
};

const StatisticsPDFDocument = ({ data }: { data: any }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.header}>
                <Text>NPAA - {data?.measure?.inForceId ? "постојећи" : "планирани"} пропис</Text>
            </View>

            {/* Basic Information */}
            <View style={styles.section}>
                <View style={styles.row}>
                    <Text style={styles.label}>Идентификациона ознака</Text>
                    <Text style={styles.value}>{data?.measure?.plannedId || '-'}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.label}>Национални пропис</Text>
                    <Text style={styles.value}>{data?.measure?.title || '-'}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.label}>Надлежни органи</Text>
                    <Text style={styles.value}>{data?.measure?.institution || '-'}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.label}>Други надлежни органи</Text>
                    <Text style={styles.value}>-</Text>
                </View>

                <View style={styles.row}>
                    <Text style={styles.label}>Усваја Влада/министарство/НБС</Text>
                    <Text style={styles.value}>
                        {data?.measure?.planAdoptByGov ? data?.measure?.planAdoptByGov : '-'}
                    </Text>
                </View>

                <View style={styles.row}>
                    <Text style={styles.label}>Усваја Скупштина</Text>
                    <Text style={styles.value}>
                        {data?.measure?.planAdoptByParl ? data?.measure?.planAdoptByParl : '-'}
                    </Text>
                </View>

                <View style={styles.row}>
                    <Text style={styles.label}>Почетак примене</Text>
                    <Text style={styles.value}>
                        {data?.measure?.planEntryIntoForce ? data?.measure?.planEntryIntoForce : '-'}
                    </Text>
                </View>
            </View>

            {/* Service Journal Information */}
            <View style={styles.section}>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={[styles.tableCell, { width: '25%' }]}>Објава у Службеном гласнику</Text>
                        <Text style={[styles.tableCell, { width: '25%' }]}>Број СГ</Text>
                        <Text style={[styles.tableCell, { width: '25%' }]}>Редни број објаве у СГ</Text>
                        <Text style={[styles.tableCell, { width: '25%' }]}>Почетак примене</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={[styles.tableCell, { width: '25%' }]}>{data?.measure?.oj}</Text>
                        <Text style={[styles.tableCell, { width: '25%' }]}>{data?.measure?.OJNumber}</Text>
                        <Text style={[styles.tableCell, { width: '25%' }]}>{data?.measure?.OJPagesNumber}</Text>
                        <Text style={[styles.tableCell, { width: '25%' }]}>
                            {data?.measure?.inForceOJ ? new Date(data.measure.inForceOJ).toLocaleDateString('sr-RS', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric'
                            }) : '-'}
                        </Text>
                    </View>
                </View>
            </View>

            {/* NPAA Structure */}
            <View style={styles.section}>
                <Text style={styles.subHeader}>Положај у оквиру NPAA структуре</Text>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={[styles.tableCell, { width: '33%' }]}>NPAA поглавље</Text>
                        <Text style={[styles.tableCell, { width: '33%' }]}>NPAA потпоглавље</Text>
                        <Text style={[styles.tableCell, { width: '34%' }]}>NPAA потпотпоглавље</Text>
                    </View>
                    {data?.npis?.map((npi: any, index: number) => (
                        <View key={index} style={styles.tableRow}>
                            <Text style={[styles.tableCell, { width: '33%' }]}>{npi.p1 || '-'}</Text>
                            <Text style={[styles.tableCell, { width: '33%' }]}>{npi.p2 || '-'}</Text>
                            <Text style={[styles.tableCell, { width: '34%' }]}>{npi.p3 || '-'}</Text>
                        </View>
                    ))}
                </View>
            </View>


            {/* SSP Structure */}
            <View style={styles.section}>
                <Text style={styles.subHeader}>Положај у оквиру ССП структуре</Text>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={[styles.tableCell, { width: '33%' }]}>ССП Наслов</Text>
                        <Text style={[styles.tableCell, { width: '33%' }]}>ССП поглавље/члан</Text>
                        <Text style={[styles.tableCell, { width: '34%' }]}>ССП члан</Text>
                    </View>
                    {data?.ssps?.map((ssp: any, index: number) => (
                        <View key={index} style={styles.tableRow}>
                            <Text style={[styles.tableCell, { width: '33%' }]}>{ssp.p1 || '-'}</Text>
                            <Text style={[styles.tableCell, { width: '33%' }]}>{ssp.p2 || '-'}</Text>
                            <Text style={[styles.tableCell, { width: '34%' }]}>{ssp.p3 || '-'}</Text>
                        </View>
                    ))}
                </View>
            </View>

            {/* Negotiation Documents */}
            <View style={styles.section}>
                <Text style={styles.subHeader}>Веза са преговарачким документом</Text>
                <View style={styles.checkboxRow}>
                    <View style={[styles.checkbox, { backgroundColor: data?.measure?.negotiationDocs?.[0]?.show ? '#000' : 'transparent' }]} />
                    <Text>Да</Text>
                    <View style={[styles.checkbox, { marginLeft: 20, backgroundColor: !data?.measure?.negotiationDocs?.[0]?.show ? '#000' : 'transparent' }]} />
                    <Text>Не</Text>
                </View>
                {data?.measure?.negotiationDocs?.[0]?.show && data?.measure?.negotiationDocs[0].names?.map((name: string, index: number) => (
                    <View key={index} style={styles.row}>
                        <Text style={styles.label}>Назив преговарачког документа:</Text>
                        <Text style={styles.value}>{name}</Text>
                    </View>
                ))}
            </View>

            {/* Strategy Documents */}
            <View style={styles.section}>
                <Text style={styles.subHeader}>Веза са стратешким документом</Text>
                <View style={styles.checkboxRow}>
                    <View style={[styles.checkbox, { backgroundColor: data?.measure?.strategyDocs?.[0]?.show ? '#000' : 'transparent' }]} />
                    <Text>Да</Text>
                    <View style={[styles.checkbox, { marginLeft: 20, backgroundColor: !data?.measure?.strategyDocs?.[0]?.show ? '#000' : 'transparent' }]} />
                    <Text>Не</Text>
                </View>
                {data?.measure?.strategyDocs?.[0]?.show && data?.measure?.strategyDocs[0].names?.map((name: string, index: number) => (
                    <View key={index} style={styles.row}>
                        <Text style={styles.label}>Назив стратешког документа:</Text>
                        <Text style={styles.value}>{name}</Text>
                    </View>
                ))}
            </View>

            {/* EU Regulations */}
            <View style={styles.section}>
                <Text style={styles.subHeader}>ЕУ прописи са којима се врши усклађивање</Text>
                <View style={styles.tableRow}>
                    <Text style={[styles.tableCell, { width: '20%' }]}>Celex ознака прописа ЕУ</Text>
                    <Text style={[styles.tableCell, { width: '60%' }]}>Назив прописа ЕУ на енглеском језику</Text>
                    <Text style={[styles.tableCell, { width: '20%' }]}>Усклађеност</Text>
                </View>
                {data?.euRegisters?.map((reg: any, index: number) => (
                    <View key={index} style={styles.tableRow}>
                        <Text style={[styles.tableCell, { width: '20%' }]}>{reg?.celexNo || '-'}</Text>
                        <Text style={[styles.tableCell, { width: '60%' }]}>{reg?.name || '-'}</Text>
                        <Text style={[styles.tableCell, { width: '20%' }]}>   {COMPACTIBILITY_MAP.hasOwnProperty(reg?.compatibility)
                            ? COMPACTIBILITY_MAP[reg?.compatibility as keyof typeof COMPACTIBILITY_MAP]
                            : '-'}
                        </Text>
                    </View>
                ))}
            </View>

            {/* Relations with national regulations */}
            <View style={styles.section}>
                <Text style={styles.subHeader}>Веза са постојећим националним прописима</Text>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={[styles.tableCell, { width: '25%' }]}>Тип везе</Text>
                        <Text style={[styles.tableCell, { width: '35%' }]}>Назив прописа</Text>
                        <Text style={[styles.tableCell, { width: '20%' }]}>Службени гласник</Text>
                        <Text style={[styles.tableCell, { width: '20%' }]}>Редни број објаве у СГ</Text>
                    </View>
                    {data?.relations?.map((relation: any, index: number) => (
                        <View key={index} style={styles.tableRow}>
                            <Text style={[styles.tableCell, { width: '25%' }]}>{relation?.relation || '-'}</Text>
                            <Text style={[styles.tableCell, { width: '35%' }]}>{relation?.title || '-'}</Text>
                            <Text style={[styles.tableCell, { width: '20%' }]}>{relation?.oj || '-'} {relation?.OJNumber || ''}</Text>
                            <Text style={[styles.tableCell, { width: '20%' }]}>{relation?.OJPagesNumber || '-'}</Text>
                        </View>
                    ))}
                </View>
            </View>

            {/* Institutions */}
            <View style={styles.section}>
                <Text style={styles.subHeader}>Институције задужене за спровођење правног оквира</Text>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={[styles.tableCell, { width: '30%' }]}>Назив институције</Text>
                        <Text style={[styles.tableCell, { width: '30%' }]}>Правни основ</Text>
                        <Text style={[styles.tableCell, { width: '20%' }]}>Ознака постојећег прописа</Text>
                        <Text style={[styles.tableCell, { width: '20%' }]}>Ознака планираног прописа</Text>
                    </View>
                    {data?.inChargeInstitutions?.map((institution: any, index: number) => (
                        <View key={index} style={styles.tableRow}>
                            <Text style={[styles.tableCell, { width: '30%' }]}>{institution?.institution || '-'}</Text>
                            <Text style={[styles.tableCell, { width: '30%' }]}>{institution?.title || '-'}</Text>
                            <Text style={[styles.tableCell, { width: '20%' }]}>{institution?.pid || '-'}</Text>
                            <Text style={[styles.tableCell, { width: '20%' }]}>{institution?.fid || '-'}</Text>
                        </View>
                    ))}
                </View>
            </View>
        </Page>
    </Document>
);

export const generateAndOpenPDF = async (data: any) => {
    try {
        // Generate PDF blob
        const blob = await pdf(<StatisticsPDFDocument data={data} />).toBlob();

        // Create URL and open in new tab
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');

        // Cleanup URL after a delay to ensure the PDF loads
        setTimeout(() => {
            URL.revokeObjectURL(url);
        }, 1000);
    } catch (error) {
        console.error('Error generating PDF:', error);
        throw error;
    }
};

// Example usage in another component:
// import { generateAndOpenPDF } from './npi-pdf';
//
// const handlePrint = async () => {
//     const data = await fetchYourData();
//     await generateAndOpenPDF(data);
// };
