import {
  Autocomplete,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaFileWord, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  getAllInstitutions,
  getFinDoc,
  getMeasureDoc,
  getMeasures,
  getNationalGroupSet,
  getRegistersManagement,
  getViewsClusterTree,
} from '../../../server/server';
import download from 'downloadjs';
import HelpButton from '../../../common/HelpButton';
import HelpPageModal from '../../modals/HelpPageModal';
import { generateYears, quarterYearEncoder } from '../../utils/util';

function MeasureView() {
  let navigate = useNavigate();
  const [term, setTerm] = useState('');
  const [institutions, setInstitutions] = useState<any>([]);
  const [registers, setRegisters] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);
  const [rowCountState, setRowCountState] = useState(6);
  const [isLoading, setIsLoading] = useState(true);
  const [translation, setTranslation] = useState<any>(null);
  const [defaultInstitution, setDefaultInstitution] = useState<any | null>();
  const [fileLoading, setFileLoading] = useState(false);
  const [helpModal, setHelpModal] = useState(false);
  const [fromYear, setFromYear] = useState('');
  const [toQ, setToQ] = useState('');
  const [fromQ, setFromQ] = useState('');
  const [toYear, setToYear] = useState('');
  const [cluster, setCluster] = useState<any>(null);
  const [selSubSubGroup, setSelSubSubGroup] = useState<any>(null);
  const [selectedSubSubGroup, setselectedSubSubGroup] = useState<any>(null);
  const [subSubGroups, setSubSubGroups] = useState<any>(null);
  const [subSubGroup, setSubSubGroup] = useState<any>(null);
  const [originalData, setOriginalData] = useState<any>(null);

  const [error, setError] = useState({
    fromYear: false,
    toQ: false,
    fromQ: false,
    toYear: false,
  });
  const closeHelpModal = () => {
    setHelpModal(false);
  };
  const downloadWord = async () => {
    setFileLoading(true);
    const resp = await getMeasureDoc(
      term,
      defaultInstitution?.id ?? '',
      translation?.id ?? '',
      quarterYearEncoder(fromYear, fromQ),
      quarterYearEncoder(toYear, toQ),
      selSubSubGroup?.id ?? '',
      selectedSubSubGroup?.groupNo ?? ''
    );
    if (resp?.status === 200) {
      setFileLoading(false);
      download(resp?.data?.data, resp?.data?.name, resp?.data?.type);
    }
  };
  const handleInstChange = (e: any, prm: any) => {
    setDefaultInstitution(prm as any);
  };
  const loadRegisters = async (offset: any = 0) => {
    const resp = await getMeasures(
      pageSize,
      offset,
      term,
      defaultInstitution?.id ?? '',
      translation?.id ?? '',
      null,
      null,
      null,
      quarterYearEncoder(fromYear, fromQ),
      quarterYearEncoder(toYear, toQ),
      selSubSubGroup?.id ?? '',
      selectedSubSubGroup?.groupNo ?? ''
    );

    if (resp?.status === 200) {
      setIsLoading(false);
      setRegisters(resp?.data?.items);
      setRowCountState(resp?.data?.total);
    }
  };

  const loadInstitutions = async () => {
    const resp = await getAllInstitutions();
    if (resp?.status) {
      setInstitutions(resp?.data);
    }
  };

  const setPageAndCall = (page: any) => {
    setPage(page);
    loadRegisters(page * pageSize);
  };
  const deleteItem = React.useCallback(
    (id: GridRowId) => () => {
      setTimeout(() => {
        setRegisters((prevRows: any) =>
          prevRows.filter((row: any) => row.id !== id)
        );
      });
    },
    []
  );
  const editItem = React.useCallback(
    (id: GridRowId) => () => {
      navigate('/views/editmeasure?id=' + id);
    },
    []
  );
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: 'cluster',
        headerName: 'Кластер',
        minWidth: 260,
        maxWidth: 260,
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex flex-col">
              <div className="font-bold  text-[#0f2765]">Кластер</div>
              <p className="text-[12px]"> {params?.row?.cluster}</p>
              <div className="font-bold text-[#0f2765]">Поглавље</div>
              <p className="text-[12px]"> {params?.row?.group}</p>
              {params?.row?.subChapter &&
                <div className="font-bold text-[#0f2765]">Подпоглавље</div>
              }
              {
                params?.row?.subChapter &&
                <p className="text-[12px]"> {params?.row?.subChapter}</p>
              }
            </div>
          );
        },
      },
      {
        field: 'name',
        headerName: 'Планирама мера',
        minWidth: 260,
        maxWidth: 260,
        flex: 1,
      },
      {
        field: 'nm',
        headerName: 'Веза са националним прописом',
        type: 'boolean',
        maxWidth: 180,
        flex: 1,
      },
      {
        field: 'em',
        headerName: 'Веза са прописом ЕУ',
        type: 'boolean',
        maxWidth: 180,
        flex: 1,
      },
      {
        field: 'pointers',
        headerName: 'Показатељи резултата',
        maxWidth: 180,
        type: 'boolean',
        flex: 1,
      },
      {
        field: 'institution',
        headerName: 'Надлежне институције',
        minWidth: 260,
        maxWidth: 260,
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex flex-col">
              <div className="font-bold  text-[#0f2765]">
                Првонадлежна институција:
              </div>
              <p className="text-[12px]"> {params?.row?.institution}</p>
              <div className="font-bold text-[#0f2765]">
                Другонадлежне институције:
              </div>
              {!params?.row?.secondaryInstitutions && (
                <p className="text-[12px]">Ниједна</p>
              )}
              <p className="text-[12px]">
                {params?.row?.secondaryInstitutions}
              </p>
            </div>
          );
        },
      },
      {
        field: 'deadlineType',
        headerName: 'Рок',
        minWidth: 120,
        maxWidth: 120,
        flex: 1,
        renderCell: (params) => {
          const deadlineTypeMap = {
            0: 'Континуирано',
            1: 'квартал/год.',
            2: 'До пристуања ЕУ',
          };
          const deadline =
            params?.row?.deadlineType === '1' &&
              params?.row?.deadline !== 'undefined/undefined'
              ? params?.row?.deadline
              : deadlineTypeMap[
              params?.row?.deadlineType as keyof typeof deadlineTypeMap
              ];
          return <p className="text-[12px]"> {deadline}</p>;
        },
      },
      {
        field: 'status',
        headerName: 'Статус испуњености мере',
        maxWidth: 230,
        flex: 1,
        renderCell: (params) => {
          const statusMap = {
            0: 'Не',
            1: 'Делимично остварен',
            2: 'Да',
          };
          return (
            <p className="text-[12px]">
              {statusMap[params?.row?.status as keyof typeof statusMap]}
            </p>
          );
        },
      },
      {
        field: 'comment',
        headerName: 'Коментар',
        maxWidth: 200,
        flex: 1,
      },
    ],
    [deleteItem, editItem]
  );
  const loadSubGroups = async () => {
    const resp = await getViewsClusterTree();
    if (resp?.status === 200 && resp?.data) {
      setOriginalData(resp.data);
      const filteredData = resp.data.filter((x: any) => x?.level !== 0);
      setSubSubGroup(filteredData);
    }
  };
  useEffect(() => {
    loadRegisters();
    loadInstitutions();
    loadSubGroups();

  }, []);

  useEffect(() => {
    loadRegisters();
  }, [term, defaultInstitution, translation, fromQ, fromYear, toQ, toYear, selSubSubGroup, selectedSubSubGroup]);

  const translations = [
    { id: 0, name: 'Неостварено' },
    { id: 1, name: 'Делимично остварено' },
    { id: 2, name: 'Остварено' },
  ];

  const handleTranslationsChange = (e: SelectChangeEvent) => {
    const t = translations.find((r) => r?.name === e.target.value);
    setTranslation(t as any);
  };

  const QUARTER_MAP = ['I', 'II', 'III', 'IV'];
  const YEAR_MAP = generateYears(2015, 2028);
  type DataItem = {
    id: number;
    name: string;
    level: number;
    parent: number;
  };
  function findLevel1Parent(selectedItem: DataItem): DataItem | undefined {
    // Izvlačenje broja nivoa iz imena odabranog elementa
    const levelNumber = selectedItem.name.match(/^\d+/)?.[0];

    // if (!levelNumber) {
    //   return undefined;
    // }

    // Pronalazak elementa nivoa 1 sa istim brojem nivoa
    const level1Parent = originalData?.find(
      (item: any) => item.level === 0 && item.id === selectedItem.parent
    );

    return level1Parent;
  }

  const handleChangeSubSubGroup = (e: any, prm: any) => {

    setselectedSubSubGroup(prm as any);
  }
  const loadSubSubGroups = async (group: any) => {
    setselectedSubSubGroup(null);
    const resp = await getNationalGroupSet(group || selSubSubGroup?.groupNo || selSubSubGroup?.id);
    if (resp?.status === 200) {
      setSubSubGroups(resp?.data);
    }
  }
  const subSubChange = (e: any, prm: any) => {
    if (prm?.name) {
      setCluster(findLevel1Parent(prm));
    } else {
      setCluster(null);
    }
    if (prm?.name) {
      loadSubSubGroups(prm?.id || prm?.groupNo);
    }

    setSelSubSubGroup(prm as any);
  };
  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full flex flex-col">
      <div className="flex items-center justify-between">
        <div className="flex gap-6">
          <h1 className="text-[#04438b] text-4xl font-bold">Преглед мера</h1>
          <HelpButton onClick={() => setHelpModal(true)} />
        </div>
        <div>
          {fileLoading ? (
            <CircularProgress />
          ) : (
            <FaFileWord
              className="text-[#043e81ae] w-8 h-8 cursor-pointer hover:text-[#043e81]"
              onClick={downloadWord}
            />
          )}
        </div>
      </div>
      <div className="rounded-lg border border-[#81848f3d] p-8 pb-4 mt-8 flex-grow">
        <h1 className="text-[#04438bae] text-3xl font-bold">Претрага</h1>
        <div className="w-full mt-6 flex items-center gap-6">
          <div className="flex flex-col items-start gap-3">
            <p className="w-[160px] text-sm text-[#404040]">По Називу</p>
            <TextField
              label="Назив мере"
              id="outlined-size-small"
              size="small"
              autoComplete="off"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
            />
          </div>
          <div className="flex flex-col items-start gap-3">
            <p className="w-[160px] text-sm text-[#404040]">
              По надлежној институцији
            </p>
            <Autocomplete
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              value={defaultInstitution}
              size="small"
              onChange={(event: any, newValue: any) =>
                handleInstChange(event, newValue)
              }
              id="controllable-states-demo"
              options={institutions}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Институција" />
              )}
            />
          </div>
          <div className="flex flex-col items-start gap-3">
            <p className="w-[140px] text-sm text-[#404040]">По статусу</p>
            <FormControl size="small" sx={{ minWidth: 140 }}>
              <InputLabel id="demo-select-small">Статус</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={translation?.name || ''}
                onChange={handleTranslationsChange}
                label="Статус"
                className="capitalize"
              >
                <MenuItem value="">
                  <em>Сви</em>
                </MenuItem>
                {translations?.map((t) => {
                  return (
                    <MenuItem
                      value={t?.name}
                      className="capitalize"
                      key={t?.id}
                    >
                      {t?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="flex pt-4 items-end">
            <FormControl size="small" sx={{ m: 1, width: 85 }}>
              {/* <InputLabel id="demo-simple-select-standard-label">
                    Квартал
                  </InputLabel> */}
              <p className="w-[160px] text-sm text-[#404040]">Од Квартала</p>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={fromQ || ''}
                error={error.fromQ}
                onFocus={() => setError({ ...error, fromQ: false })}
                onChange={(e) => setFromQ(e.target.value)}
              // label="Квартал"
              >
                {QUARTER_MAP?.map((q) => {
                  return <MenuItem value={q}>{q}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ m: 1, width: 85 }}>
              {/* <InputLabel id="demo-simple-select-standard-label">
                    Година
                  </InputLabel> */}
              <p className="w-[160px] text-sm text-[#404040]">Од Године</p>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={fromYear || ''}
                onChange={(e) => setFromYear(e.target.value)}
                error={error.fromYear}
                onFocus={() => setError({ ...error, fromYear: false })}
              >
                {YEAR_MAP?.map((y) => {
                  return <MenuItem value={y}>{y}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </div>
          <div className="flex pt-4 items-end">
            <FormControl size="small" sx={{ m: 1, width: 85 }}>
              {/* <InputLabel id="demo-simple-select-standard-label">
                    Квартал
                  </InputLabel> */}
              <p className="w-[160px] text-sm text-[#404040]">До Квартала</p>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={toQ || ''}
                onChange={(e) => setToQ(e.target.value)}
                error={error.toQ}
                onFocus={() => setError({ ...error, toQ: false })}
              >
                {QUARTER_MAP?.map((q) => {
                  return <MenuItem value={q}>{q}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ m: 1, width: 85 }}>
              {/* <InputLabel id="demo-simple-select-standard-label">
                    Година
                  </InputLabel> */}
              <p className="w-[160px] text-sm text-[#404040]">До Године</p>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={toYear || ''}
                onChange={(e) => setToYear(e.target.value)}
                error={error.toYear}
                onFocus={() => setError({ ...error, toYear: false })}
              >
                {YEAR_MAP?.map((y) => {
                  return <MenuItem value={y}>{y}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="flex gap-1">
          <div className="flex flex-col items-start gap-3 max-w-[230px] min-w-[120px]">
            <p className="w-[260px] text-[#404040]">Кластер</p>{' '}
            <p className="text-sm text-[#04438b]">{cluster?.name ?? '-'}</p>
          </div>
          <div className="flex flex-col items-start gap-3">
            <p className="w-[260px] text-[#404040]">Поглавље</p>
            <div className="flex flex-col">
              <Autocomplete
                value={selSubSubGroup}
                size="small"
                onChange={(event, newValue) => subSubChange(event, newValue)}
                // onFocus={() => setErrorMap({ ...errorMap, group: '' })}
                id="controllable-states-demo"
                noOptionsText={'Не постоји подгрупа'}
                options={subSubGroup || ['']}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 340 }}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      ...props.style,
                      // pointerEvents: option.level === 3 ? 'auto' : 'none',
                      // color:
                      //   option.level === 3 ? 'inherit' : 'rgba(0, 0, 0, 0.38)',
                    }}
                  >
                    <span>{option.name}</span>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Поглавље" />
                )}
              />
            </div>
            {/* {errorMap?.group && (
              <span style={{ color: 'red' }}>Ово поље је обавезно</span>
            )} */}
          </div>
          {selSubSubGroup && <div className="flex flex-col items-start gap-3">
            <p className="w-[260px] text-[#404040]">Подпоглавље</p>
            <div className="flex flex-col">
              <Autocomplete
                value={selectedSubSubGroup}
                size="small"
                onChange={(event, newValue) => handleChangeSubSubGroup(event, newValue)}
                id="controllable-states-demo"
                noOptionsText={'Не постоји Подпоглавље'}
                options={subSubGroups || ['']}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 340 }}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      ...props.style,

                    }}
                  >
                    <span>{option.name}</span>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Подпоглавље" />
                )}
              />
            </div>
          </div>}</div>
        <div className="mt-5 w-full min-h-[430px]">
          <DataGrid
            rowCount={rowCountState}
            loading={isLoading}
            pagination
            disableColumnMenu
            paginationMode="server"
            onPageChange={(newPage) => setPageAndCall(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            page={page}
            pageSize={pageSize}
            rows={registers}
            columns={columns}
            style={{ width: '100%', zIndex: 0 }} /* Set width to 100% */
            rowsPerPageOptions={[5]}
            getRowHeight={() => 'auto'}
            autoHeight={true}
            disableSelectionOnClick
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје мере са задатим критеријумом
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје мере са задатим критеријумом
                </Stack>
              ),
            }}
          />
        </div>
      </div>
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'measureView'}
      />
    </div>
  );
}

export default MeasureView;
